// Variable overrides

// public static Color Blue = Color.FromArgb(166, 219, 225);
// 	public static Color Salmon = Color.FromArgb(233, 137, 115);
// 	public static Color Target = Color.FromArgb(219, 245,239);
// 	public static Color Input = Color.FromArgb(220, 237, 248);
// 	public static Color HitPolicy = Color.FromArgb(250, 230, 235);
// 	public static Color Rule = Color.FromArgb(237, 233, 251);
// 	public static Color Result = Color.FromArgb(246, 221, 247);
// 	public static Color Border = Color.FromArgb(192, 192, 192);
// 	public static Color HitPolicyHeaderFont=Color.DimGray;

$yellow: rgb(249, 237, 199);
$darkYellow: rgb(238, 204, 97);
$blue: rgb(166, 219, 225);
$mediumBlue: rgb(80, 185, 196);
$darkBlue: rgb(49, 134, 143);

$salmon: rgb(233, 137, 115);
$darkSalmon: rgb(195, 62, 31);

$darkGray: #585858;
$muted: #6c757d;
$darkDarkGray: #222222;
$lightGray: rgb(190, 189, 189);
$lighterGray: rgb(220, 220, 220);

$pink: rgb(250, 230, 235);

// :export {
//   white: white;
//   black: black;

//   blue: $blue;
//   mediumBlue: $mediumBlue;
//   darkBlue: $darkBlue;

//   salmon: $salmon;
//   darkSalmon: $darkSalmon;
//   pink: $pink;
//   yellow: $yellow;
//   darkYello: $darkYellow;

//   lightGray: $lightGray;
//   darkGray: $darkGray;
//   darkDarkGray: $darkDarkGray;
// }

$theme-colors: (
  "primary": $mediumBlue,
  "secondary": gray,
  "success": $mediumBlue,
  "danger": $salmon,
  "info": $blue,
  "warning": $darkYellow,
);

$navbar-height: 55px;
